// app/javascript/controllers/documents_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static values = { documentId: Number };
  static targets = ["content", "token", "title", "suggestionsCount"];

  connect() {

    this.isGettingFeedback = false;
    this.retryCount = 0;

    // this.adjustInputWidth();
    this.removeFileToolsButtonGroup();

    this.updateContentVisibility();

    this.moveTrixToolbar().then(() => {
      const trixEditor = document.querySelector("trix-editor");
      if (trixEditor) {
        const content = trixEditor.value.trim();
        const sentenceCount = this.countSentences(content);

        // Check if the content of the trix editor has at least three sentences before getting feedback
        if (sentenceCount >= 3) {
          setTimeout(() => {
            this.getFeedback();
          }, 0);
        }

        trixEditor.addEventListener("trix-change", this.handleTrixChange.bind(this));
        trixEditor.addEventListener("trix-paste", () => {
          setTimeout(() => this.handleTrixChange({ target: trixEditor }, true), 0);
        });
      }
    });

    setTimeout(() => {
      this.toggleUnsupportedMessage();
    }, 0);


    // Add this event listener to call toggleUnsupportedMessage() whenever the window is resized
    window.addEventListener("resize", () => this.toggleUnsupportedMessage());

    const suggestionsList = document.getElementById('suggestions-list');

    // Custom event to be dispatched when a card is removed
    this.cardRemovedEvent = new CustomEvent('cardRemoved');

    // Listen for the custom 'cardRemoved' event
    suggestionsList.addEventListener('cardRemoved', () => {
      const cards = suggestionsList.getElementsByClassName('card');
      if (cards.length === 0) {
        this.getFeedback();
      }
    });

    this.debouncedUpdateDocument = this.debounce(this.updateDocument.bind(this), 600);

  }

  isMobileDevice() {
    return (
      /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ) ||
      (window.innerWidth <= 800 && window.innerHeight <= 600)
    );
  }


  toggleUnsupportedMessage() {
    const unsupportedMessage = document.getElementById("unsupported-message");


    if (this.isMobileDevice()) {
      unsupportedMessage.style.display = "flex";


    } else {
      unsupportedMessage.style.display = "none";
    }
  }

  showLoadingIndicator() {
    const loadingIndicator = document.createElement("div");
    loadingIndicator.id = "loading_indicator";
    loadingIndicator.classList.add("loader");
    loadingIndicator.style.display = "block";

    const suggestionsList = document.getElementById("suggestions-list");
    if (suggestionsList) {
      suggestionsList.appendChild(loadingIndicator);
    }
  }


  hideLoadingIndicator() {
    const loadingIndicator = document.getElementById("loading_indicator");
    if (loadingIndicator) {
      loadingIndicator.remove();
    }
  }


  handleTrixChange(event, forceFeedback = false) {


    const newContent = event.target.value;
    const sentenceCount = this.countSentences(newContent);

    const suggestionCards = this.element.querySelectorAll('.suggestion-category');
    const count = suggestionCards.length;

    this.debouncedUpdateDocument();

    if ((count == 0 || forceFeedback) &&  (sentenceCount >= 5)) {
      this.getFeedback();
    }
  }

  countSentences(content) {
    const sentenceSeparators = content.match(/[.?!]/g);
    return sentenceSeparators ? sentenceSeparators.length : 0;
  }


  removeFileToolsButtonGroup() {
    const buttonGroup = document.querySelector(".trix-button-group--file-tools");

    if (buttonGroup) {
      buttonGroup.remove();
    }
  }

  moveTrixToolbar() {
    return new Promise((resolve) => {

      const editorContainer = document.getElementById('editor-container');
      const trixToolbar = document.querySelector('[id^="trix-toolbar-"]');

      if (editorContainer && trixToolbar) {
        editorContainer.appendChild(trixToolbar);
      }
      resolve();
    });
  }



  updateContentVisibility() {
    const temporaryElements = document.querySelectorAll(".temporary");
    const suggestionsContainer = document.getElementById("suggestions-container");

    if (suggestionsContainer) {
      const feedbackCount = parseInt(suggestionsContainer.dataset.feedbackCount);

      if (temporaryElements && !isNaN(feedbackCount)) {
        temporaryElements.forEach((element) => {
          element.style.display = feedbackCount > 0 ? "none" : "block";
        });
      }
    }
  }

  // async getFeedback() {

  //   if (this.isGettingFeedback) {
  //     return;
  //   }

  //   // Set the flag to true at the beginning of the function
  //   this.isGettingFeedback = true;

  //   const documentId = this.element.dataset.documentId;
  //   const url = `/documents/${documentId}/feedbacks/new`;
  //   const suggestionsList = document.getElementById("suggestions-list");

  //   // Clear the suggestions list
  //   suggestionsList.innerHTML = '';


  //   this.showLoadingIndicator();


  //   try {
  //     const response = await fetch(url, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Accept': 'application/json',
  //       },
  //     });

  //     if (!response.ok) {
  //       this.isGettingFeedback = false;
  //       setTimeout(() => {
  //         this.getFeedback();
  //       }, 6000);
  //       // throw new Error(`HTTP error! status: ${response.status}`);
  //     } else {


  //       const data = await response.json();
  //       const lastFeedback = data.last_feedback;

  //       if (lastFeedback === null) {
  //         // Do something if lastFeedback is null
  //         this.isGettingFeedback = false;
  //         setTimeout(() => {
  //           this.getFeedback();
  //         }, 6000);
  //       } else {
  //         // Do something else if lastFeedback is not null
  //         this.displayFeedback(lastFeedback);
  //         this.updateSuggestionsCount();
  //         // Fetch updated scores and update the score cards
  //         this.fetchUpdatedScores();
  //         this.isGettingFeedback = true;
  //       }


  //     }



  //   } catch (error) {
  //     console.error("There was a problem with the fetch operation:", error);
  //   }
  // }

  async getFeedback() {
    if (this.isGettingFeedback || this.retryCount >= 3) {
      return;
    }

    // Set the flag to true at the beginning of the function
    this.isGettingFeedback = true;
    this.retryCount = this.retryCount || 0;

    const documentId = this.element.dataset.documentId;
    const url = `/documents/${documentId}/feedbacks/new`;
    const suggestionsList = document.getElementById("suggestions-list");

    // Clear the suggestions list
    suggestionsList.innerHTML = '';

    this.showLoadingIndicator();

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      if (!response.ok) {
        this.isGettingFeedback = false;
        this.retryCount++;
        if (this.retryCount < 3) {
          setTimeout(() => {
            this.getFeedback();
          }, 6000);
        }
        // throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        const data = await response.json();
        const lastFeedback = data.last_feedback;

        if (lastFeedback === null) {
          // Do something if lastFeedback is null
          this.isGettingFeedback = false;
          this.retryCount++;
          if (this.retryCount < 3) {
            setTimeout(() => {
              this.getFeedback();
            }, 6000);
          }
        } else {
          // Do something else if lastFeedback is not null
          this.displayFeedback(lastFeedback);
          this.updateSuggestionsCount();
          // Fetch updated scores and update the score cards
          this.fetchUpdatedScores();
          this.isGettingFeedback = true;
          this.retryCount = 0;
        }
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  }



  parseFeedbackText(feedbackText) {

    const feedbackLines = feedbackText.split("\n");
    const feedbackArray = [];

    feedbackLines.forEach(line => {
      const match = line.match(/^\d+\. (.+?) - (.+)$/);

      if (match) {
        const category = match[1];
        const content = match[2];

        feedbackArray.push({ category, content });
      }
    });

    if(feedbackArray.length == 0) {

      this.isGettingFeedback = false;
        setTimeout(() => {
          this.getFeedback();
        }, 60000);

    } else {

      return feedbackArray;
    }
  }

  // Tried to preserve examples but failed, will look at this later
  // parseFeedbackText(feedbackText) {
  //   const feedbackLines = feedbackText.split(/\n\n(?=\d+\.)/);
  //   const feedbackArray = [];

  //   feedbackLines.forEach(line => {
  //       const lines = line.split("\n");
  //       const match = lines[0].match(/^(\d+)\. (.+?) - (.+)$/);

  //       if (match) {
  //           const category = match[2];
  //           const content = [match[3], ...lines.slice(1)].join("\n");

  //           feedbackArray.push({ category, content });
  //       }
  //   });

  //   if (feedbackArray.length === 0) {
  //       this.isGettingFeedback = false;
  //       setTimeout(() => {
  //           this.getFeedback();
  //       }, 60000);
  //   } else {
  //       return feedbackArray;
  //   }
  // }


  displayFeedback(feedbackText) {

    const feedbackArray = this.parseFeedbackText(feedbackText);
    const suggestionsList = document.getElementById("suggestions-list");

    const fragment = document.createDocumentFragment();



    // Create a new card for each feedback item
    feedbackArray.forEach(feedback => {
      // Create a new card for the feedback
      const card = document.createElement("div");
      card.classList.add("container", "mt-5");

      const row = document.createElement("div");
      row.classList.add("row");

      const col = document.createElement("div");
      col.classList.add("col-12");

      const feedbackCard = document.createElement("div");
      feedbackCard.classList.add("card");

      const cardBody = document.createElement("div");
      cardBody.classList.add("card-body");

      const suggestionCategory = document.createElement("div");
      suggestionCategory.classList.add("suggestion-category");
      suggestionCategory.textContent = feedback.category || "Category";

      const cardText = document.createElement("p");
      cardText.classList.add("card-text");
      cardText.textContent = feedback.content || "This is a sample suggestion content. You can replace this text with the actual suggestion content.";

      const cardFooter = document.createElement("div");
      cardFooter.classList.add("card-footer");

      const deleteLink = document.createElement("a");
      deleteLink.classList.add("delete-link");
      deleteLink.href = "#";
      deleteLink.setAttribute("data-action", "click->documents#deleteSuggestion");

      const trashIcon = document.createElement("i");
      trashIcon.classList.add("fas", "fa-trash-alt");
      deleteLink.appendChild(trashIcon);

      cardFooter.appendChild(deleteLink);
      cardBody.appendChild(suggestionCategory);
      cardBody.appendChild(cardText);
      feedbackCard.appendChild(cardBody);
      feedbackCard.appendChild(cardFooter);
      col.appendChild(feedbackCard);
      row.appendChild(col);
      card.appendChild(row);

      // Append the new card to the fragment
      fragment.appendChild(card);



    });
    // Append the new card to the suggestions list
    suggestionsList.appendChild(fragment);

    this.hideLoadingIndicator();

  }

  async updateDocument() {

    const documentId = this.element.dataset.documentId;
    const content = this.contentTarget.value;
    const title = this.titleTarget.value;
    const authenticityToken = this.tokenTarget.value;
    const url = `/documents/${documentId}`;

    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': authenticityToken
      },
      body: JSON.stringify({
        document: {
          content: content,
          title: title
        }
      })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }


  deleteSuggestion(event) {
    const suggestionsList = document.getElementById('suggestions-list');
    event.preventDefault();
    const card = event.target.closest(".container");
    card.remove();
    this.updateSuggestionsCount();
    suggestionsList.dispatchEvent(this.cardRemovedEvent);
  }

  updateSuggestionsCount() {
    const suggestionCards = this.element.querySelectorAll('.suggestion-category');
    const count = suggestionCards.length;
    this.suggestionsCountTarget.textContent = count;
    this.isGettingFeedback = false;
  }

  async fetchUpdatedScores() {
    const documentId = this.element.dataset.documentId;
    const url = `/documents/${documentId}/obtain_scores`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const scores = await response.json();

      if (!this.validScores(scores)) {
        this.fetchUpdatedScores();
      } else {

        this.updateScoreCards(scores);
      }

    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  }

  async fetchLastFeedback() {
    const documentId = this.element.dataset.documentId;
    const url = `/documents/${documentId}/obtain_feedback`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const feedback = await response.json();

      if (feedback.display == 1) {
        this.fetchUpdatedScores();
      } else {

        this.updateScoreCards(scores);
      }

    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  }

  validScores(scores) {
    return Object.values(scores).every((score) => typeof score === 'number' && score > 0);
  }


  updateScoreCards(scores) {
    const scoreCards = document.querySelectorAll('#analytics-container .card-text');
    scoreCards.forEach(card => {
      let scoreTitle = card.textContent.trim();
      if(scoreTitle == "Overall score") {
        scoreTitle = "Overall";
      }
      const scoreKey = scoreTitle.toLowerCase().replace(' ', '_') + '_score';
      if (scoreKey in scores) {
        const scoreCard = card.parentElement;
        scoreCard.querySelector('.card-title').textContent = scores[scoreKey];
      }
    });
  }

  debounce(func, wait) {
    let timeout;
    return (...args) => {
      const later = () => {
        timeout = null;
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }
}
